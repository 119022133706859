import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-yonkers-new-york.png'
import image0 from "../../images/cities/scale-model-of-the-christmas-house-of-the-garabedian-family-in-yonkers-new-york.png"
import image1 from "../../images/cities/scale-model-of-bronx-little-italy-in-yonkers-new-york.png"
import image2 from "../../images/cities/scale-model-of-a-bronx-tale-street-in-yonkers-new-york.png"
import image3 from "../../images/cities/scale-model-of-white-plains-park-in-yonkers-new-york.png"
import image4 from "../../images/cities/scale-model-of-wave-hill-public-gardens-in-yonkers-new-york.png"
import image5 from "../../images/cities/scale-model-of-nybg-ticket-counter-in-yonkers-new-york.png"
import image6 from "../../images/cities/scale-model-of-van-der-donck-park-in-yonkers-new-york.png"
import image7 from "../../images/cities/scale-model-of-the-edgar-allan-poe-cottage-in-yonkers-new-york.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Yonkers'
            state='New York'
            image={image}
            lat='40.9312099'
            lon='-73.89874689999999'
            attractions={ [{"name": "The Christmas House Of The Garabedian Family", "vicinity": "1605 Pelham Pkwy N, The Bronx", "types": ["point_of_interest", "establishment"], "lat": 40.8578323, "lng": -73.83990690000002}, {"name": "Bronx Little Italy", "vicinity": "2396 Arthur Ave, The Bronx", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 40.8555564, "lng": -73.8875746}, {"name": "A BRONX TALE STREET", "vicinity": "648 E 187th St, The Bronx", "types": ["point_of_interest", "establishment"], "lat": 40.8548751, "lng": -73.88618409999998}, {"name": "White Plains Park", "vicinity": "White Plains", "types": ["park", "point_of_interest", "establishment"], "lat": 41.03398620000001, "lng": -73.76290970000002}, {"name": "Wave Hill Public Gardens", "vicinity": "675 W 252nd St, The Bronx", "types": ["art_gallery", "park", "point_of_interest", "establishment"], "lat": 40.8978009, "lng": -73.91138739999997}, {"name": "NYBG Ticket Counter", "vicinity": "Bronx Park Rd, The Bronx", "types": ["park", "point_of_interest", "establishment"], "lat": 40.8664858, "lng": -73.88073680000002}, {"name": "Van Der Donck Park", "vicinity": "41 Dock St, Yonkers", "types": ["park", "point_of_interest", "establishment"], "lat": 40.9354113, "lng": -73.9013832}, {"name": "The Edgar Allan Poe Cottage", "vicinity": "2640 Grand Concourse, The Bronx", "types": ["museum", "point_of_interest", "establishment"], "lat": 40.8655316, "lng": -73.8942093}] }
            attractionImages={ {"The Christmas House Of The Garabedian Family":image0,"Bronx Little Italy":image1,"A BRONX TALE STREET":image2,"White Plains Park":image3,"Wave Hill Public Gardens":image4,"NYBG Ticket Counter":image5,"Van Der Donck Park":image6,"The Edgar Allan Poe Cottage":image7,} }
       />);
  }
}